import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 4×8\\@70%1rm`}</p>
    <p>{`Single Leg Bent Leg Bridge 4×8/leg`}</p>
    <p>{`then,`}</p>
    <p>{`25-DB Box Step Overs`}</p>
    <p>{`(35/25’s)(Rx+50/35’s) 24/20” box`}</p>
    <p>{`50-Med Ball Situps (20/14`}{`#`}{`)`}</p>
    <p>{`20-DB Box Step Overs`}</p>
    <p>{`40-Med Ball Situps`}</p>
    <p>{`15-DB Box Step Overs`}</p>
    <p>{`30-Med Ball Situps`}</p>
    <p>{`10-DB Box Step Overs`}</p>
    <p>{`20-Med Ball Situps`}</p>
    <p>{`5-DB Box Step Overs`}</p>
    <p>{`10-Med Ball Situps`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      